import styled from "styled-components";
import * as React from "react";
import { useState, useRef, useContext, useMemo, useEffect } from "react";
import { AppText, NewAppSelect } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { NewSelectField } from "../../Field";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import "rc-time-picker/assets/index.css";
import * as Sentry from "@sentry/react";
import { NoEventDateModal, DeleteNoEventDateModal } from "../../modal";
import { timezone_list_items } from "../../../static";
import { sortByDayOfWeek } from "../../../utils/sorts";

import { appToast } from "../../../utils/toast";

import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";
import { info } from "../../../images/NewDesign";
import { NewTimeField } from "../../Field";
import { PhoenixInputField } from "../../Field/Phoenix/PhoenixInputField";
import { ModalContext } from "../../../context";
import { Modal } from "../../../Components/modal";
import CustomDateTable from "./scheduling/CustomDateTable";
import FederalDateTable from "./scheduling/FederalDateTable";
import { useDebounce, useFirstRender } from "../../../utils/hooks";
import { SkeletonBlock } from "../../UI/SkeletonBlock";
import { DAYS_OF_THE_WEEK } from "../../../utils/format";
import { useFlags } from "launchdarkly-react-client-sdk";

interface IExampleTime {
  day: string;
  start_hour?: number;
  start_minute?: number;
  end_hour?: number;
  end_minute?: number;
}

const SUBMIT_TIME_STEP = gql`
  mutation SubmitTimeStep($data: OrganizationHoursInput) {
    configureGlobalSchedule(data: $data) {
      id
      demo_duration
      demo_overflow
      callback_duration
      callback_overflow
      decision_call_duration
      decision_call_overflow
      organization_hours {
        id
        day
        start_time
        end_time
        updated_at
      }
    }
  }
`;

const FETCH_TIMESLOT_AVAILABILITY = gql`
  query FetchOrgTimeslotAvailability {
    fetchOrgTimeslotAvailability {
      day
      id
      max_booking
      organization_id
    }
  }
`;

const UPDATE_ORG_SHOW_HISTORICAL_EVENTS = gql`
  mutation updateOrgShowHistoricalEvents($show_historical_events: Boolean!) {
    updateOrgShowHistoricalEvents(show_historical_events: $show_historical_events) {
      id
      show_historical_events
    }
  }
`;

const UPDATE_TIMESLOT_AVAILABILITY = gql`
  mutation UpdateOrgTimeslotAvailability(
    $updateOrgTimeslotAvailabilityId: String!
    $data: OrgTimeslotAvailabilityInput!
  ) {
    updateOrgTimeslotAvailability(id: $updateOrgTimeslotAvailabilityId, data: $data) {
      day
      max_booking
      id
      organization_id
    }
  }
`;

const BATCH_UPDATE_TIMESLOT_AVAILABILITY = gql`
  mutation UpdateOrgTimeslotAvailabilities($data: [OrgAvailabilityUpdateInput!]!) {
    updateOrgTimeslotAvailabilities(data: $data) {
      day
      max_booking
      id
      organization_id
    }
  }
`;

const GET_GLOBAL_SCHEDULE = gql`
  {
    fetchOrganization {
      id
      timezone
      demo_duration
      demo_overflow
      callback_duration
      callback_overflow
      decision_call_duration
      decision_call_overflow
      show_historical_events
      organization_hours {
        id
        day
        start_hour
        start_minute
        end_minute
        end_hour
      }
      double_booking
      enable_time_constraint
    }
  }
`;

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
  tooltipText?: string;
}

const demotimeminsOptions = [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
const callbackminsOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

interface TimeslotAvailability {
  day: string;
  id: string;
  max_booking: number;
  organization_id: string;
}

interface MyFormikProps {
  demo_duration: number;
  demo_overflow: number;
  callback_duration: number;
  callback_overflow: number;
  decision_call_duration: number;
  decision_call_overflow: number;
  enable_pay_period_view: boolean;
  organization_hours: IExampleTime[];
  timezone: string;
  double_booking: boolean;
  enable_time_constraint: boolean;
  show_historical_events: boolean;
}

const stepOneSchema = Yup.object().shape({
  demo_overflow: Yup.number()
    .integer("Demo overflow time must be a valid number")
    .min(0, "Demo overflow time must be between 0 and 60 minutes!")
    .max(60, "Demo overflow time must be between 0 and 60 minutes!")
    .required("Demo overflow time is required!"),
  callback_overflow: Yup.number()
    .integer("Call Back overflow time must be a valid number")
    .min(0, "Call Back overflow time must be between 0 and 60 minutes!")
    .max(60, "Call Back overflow time must be between 0 and 60 minutes!")
    .required("Call Back overflow time is required!"),
  decision_call_overflow: Yup.number()
    .integer("Decision Call overflow time must be a valid number")
    .min(0, "Decision Call overflow time must be between 0 and 60 minutes!")
    .max(60, "Decision Call overflow time must be between 0 and 60 minutes!")
    .required("Decision Call overflow time is required!"),
  demo_duration: Yup.number()
    .integer("Demo Time Duration must be an integer!")
    .min(1, "Demo Time Duration must be at least one minute!")
    .max(480, "Demo Time Duration must at most 480 minutes!")
    .required("Demo Time Duration is not entered!"),
  callback_duration: Yup.number()
    .integer("Call Back Time Duration must be an integer!")
    .min(1, "Call Back Time Duration must be at least one minute!")
    .max(480, "Call Back Time Duration must at most 480 minutes!")
    .required("Call Back Time Duration is not entered!"),
  decision_call_duration: Yup.number()
    .integer("Decision Call Time Duration must be an integer!")
    .min(1, "Decision Call Time Duration must be at least one minute!")
    .max(480, "Decision Call Time Duration must at most 480 minutes!")
    .required("Decision Call Time Duration is not entered!"),
  timezone: Yup.string().required("Timezone required"),
  organization_hours: Yup.array().of(
    Yup.object().shape({
      day: Yup.string(),
      start_hour: Yup.number().notRequired().nullable(),
      start_minute: Yup.number().integer().notRequired().nullable(),
      end_minute: Yup.number().integer().notRequired().nullable(),
      end_hour: Yup.number()
        .nullable()
        .moreThan(Yup.ref("start_hour"), "End hour must be after start hour")
        .when("start_hour", {
          is: (start_hour) => !!start_hour || start_hour === 0,
          then: Yup.number().required("End time required"),
          otherwise: Yup.number().notRequired().nullable(),
        }),
    }),
  ),
  double_booking: Yup.boolean(),
  enable_time_constraint: Yup.boolean(),
  show_historical_events: Yup.boolean(),
});

const StepTimesV2: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding, tooltipText }) => {
  const history = useHistory();

  // formik doesn't have an onchange event for the value changes, so we have to use this.
  // note ( a ref change will not trigger a useEffect and I didn't want to use a parallel state var for the values. )
  // the downside if we have to trigger this debounce manually onChange

  const formikRef = useRef<FormikProps<MyFormikProps>>(null);

  const [initialPageLoad, setInitialPageLoad] = useState<boolean>(true);

  const [selectedCustomEventDateYear, setSelectedCustomEventDateYear] = useState<number | string>("all");
  const [selectedFederalYear, setSelectedFederalYear] = useState<number>(new Date().getFullYear());

  const { showHistoricalEventsToggle } = useFlags();

  const CUSTOM_YEAR_OPTIONS = useMemo(() => {
    const years = [];

    years.push({
      label: "All Years",
      value: "all",
    });

    for (let i = 0; i < 10; i++) {
      const year = new Date().getFullYear() + i;
      years.push({
        label: year.toString(),
        value: year,
      });
    }
    return years;
  }, []);

  const FEDERAL_YEAR_OPTIONS = useMemo(() => {
    const years = [];

    for (let i = 0; i < 10; i++) {
      const year = new Date().getFullYear() + i;
      years.push({
        label: year.toString(),
        value: year,
      });
    }
    return years;
  }, []);

  const [debounceListener, setDebounceListener] = useState<boolean | undefined>(undefined);

  useDebounce(
    () => {
      if (formikRef?.current?.isValid && debounceListener !== undefined) {
        formikRef?.current?.submitForm();
      }
    },
    [debounceListener],
    1000,
  );

  const triggerDebounce = () => {
    if (debounceListener === undefined) setDebounceListener(true);
    else setDebounceListener(!debounceListener);
  };

  const {
    showNoEventModal,
    setShowNoEventModal,
    showDeleteNoEventModal,
    setShowDeleteNoEventModal,
    noEventModalData,
    setNoEventModalData,
  } = useContext(ModalContext);

  const [configureGlobalSchedule, { loading: loadingScheduleUpdate, error: errorScheduleUpdate }] = useMutation(
    SUBMIT_TIME_STEP,
    {
      onCompleted({ configureGlobalSchedule }) {
        console.log("configureGlobalSchedule: ", configureGlobalSchedule);
        if (!configureGlobalSchedule) {
          appToast("Error: Something went wrong.");
          return;
        }
        if (isOnboarding) {
          history.push(`/onboarding?step=${step + 1}`);
        } else {
          appToast("Updated Scheduling Details");
        }
      },
      onError({ message }) {
        console.log("Error in configureGlobalSchedule: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `configureGlobalSchedule GraphQL Error: ${message}`,
        });
      },
    },
  );

  const { loading: queryLoading, error: queryError, data } = useQuery(GET_GLOBAL_SCHEDULE, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast(message);
    },
    onCompleted({ getGlobalSchedule }) {
      setInitialPageLoad(false);
    },
  });

  // ------------------ Timeslot Availability ------------------

  interface ITimeslot {
    day: string;
    id: string;
    max_booking: number;
    organization_id: string;
  }

  const [timeslotData, setTimeslotData] = useState<ITimeslot[]>([]);

  const { data: rawTimeslotData, loading: timeslotLoading, error: timeslotError } = useQuery(
    FETCH_TIMESLOT_AVAILABILITY,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
        appToast(message);
      },

      onCompleted({ fetchOrgTimeslotAvailability }) {
        console.log("fetchOrgTimeslotAvailability: ", fetchOrgTimeslotAvailability);
        setTimeslotData(
          fetchOrgTimeslotAvailability?.map((item: any) => {
            return {
              day: item.day,
              id: item.id,
              max_booking: item.max_booking,
              organization_id: item.organization_id,
            } as ITimeslot;
          }),
        );
      },
    },
  );

  // debounce the timeslot availability update
  const [updateTimeslotAvailability] = useMutation(BATCH_UPDATE_TIMESLOT_AVAILABILITY, {
    onCompleted({ updateOrgTimeslotAvailabilities }) {
      console.log("updateOrgTimeslotAvailabilities: ", updateOrgTimeslotAvailabilities);
      appToast("Updated day availability");
    },
    onError({ message }) {
      console.log("Error in updateOrgTimeslotAvailabilities: ", message);
      appToast(message);
    },
  });

  const [updateOrgShowHistoricalEvents, { loading: loadingUpdateOrgShowHistoricalEvents }] = useMutation(
    UPDATE_ORG_SHOW_HISTORICAL_EVENTS,
    {
      onCompleted({ updateOrgShowHistoricalEvents }) {
        appToast("Updated show historical events setting");
      },
      onError({ message }) {
        appToast(message);
      },
      refetchQueries: ["fetchOrganization"],
    },
  );

  const isFirstRender = useFirstRender();

  useDebounce(
    () => {
      if (isFirstRender) return;

      const timeslotDataWithMaxBooking = timeslotData?.map((timeslot) => ({
        id: timeslot.id,
        day: timeslot.day,
        max_booking: timeslot.max_booking,
      }));

      const timeslotDataWithMaxBookingToCheckLocal = timeslotDataWithMaxBooking.sort((a: any, b: any) =>
        sortByDayOfWeek(a.day, b.day),
      );

      const timeslotDataWithMaxBookingToCheckServer = rawTimeslotData?.fetchOrgTimeslotAvailability
        ?.map((timeslot: any) => ({
          id: timeslot.id,
          day: timeslot.day,
          max_booking: timeslot.max_booking ?? null,
        }))
        .sort((a: any, b: any) => sortByDayOfWeek(a.day, b.day));

      if (
        JSON.stringify(timeslotDataWithMaxBookingToCheckLocal) ===
        JSON.stringify(timeslotDataWithMaxBookingToCheckServer)
      ) {
        return;
      }

      updateTimeslotAvailability({
        variables: {
          data: timeslotDataWithMaxBooking,
        },
      });
    },

    [timeslotData],
    2000,
  );

  const loading = queryLoading || timeslotLoading;

  const error = queryError || errorScheduleUpdate || errorScheduleUpdate || timeslotError;

  if (loading && initialPageLoad) {
    return (
      <Page>
        <TopAlignDiv>
          <OptionsDiv>
            <OptionsLeft>
              <RowDiv>
                <AppText fontSize={22} fontWeight={500} noWrap>
                  Scheduling Details
                </AppText>
                <PhoenixIcon
                  data-tip="Transfer Tab"
                  data-for="scheduling-tooltip"
                  variant="brand"
                  svg={info}
                  size={16}
                />
              </RowDiv>
            </OptionsLeft>
            <OptionsRight />
          </OptionsDiv>
        </TopAlignDiv>

        <Main>
          <SkeletonBlock height={450} width={"100%"} borderRadius={4} />
          <SkeletonBlock height={450} width={"100%"} borderRadius={4} />
          <SkeletonBlock height={450} width={"100%"} borderRadius={4} />
        </Main>
      </Page>
    );
  }
  if (error) {
    return <AppText>Error loading schedule.</AppText>;
  }

  return (
    <>
      {" "}
      {showNoEventModal && (
        <Modal open={showNoEventModal} onClose={() => setShowNoEventModal(false)}>
          <NoEventDateModal />
        </Modal>
      )}
      {showDeleteNoEventModal && (
        <Modal open={showDeleteNoEventModal} onClose={() => setShowDeleteNoEventModal(false)}>
          <DeleteNoEventDateModal />
        </Modal>
      )}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          demo_duration: data?.fetchOrganization.demo_duration || 30,
          demo_overflow: data?.fetchOrganization.demo_overflow || 0,
          callback_duration: data?.fetchOrganization.callback_duration,
          decision_call_duration: data?.fetchOrganization.decision_call_duration,
          callback_overflow: data?.fetchOrganization.callback_overflow || 0,
          decision_call_overflow: data?.fetchOrganization.decision_call_overflow || 0,
          enable_pay_period_view: data?.fetchOrganization.enable_pay_period_view,
          organization_hours: data?.fetchOrganization?.organization_hours
            ?.slice()
            ?.map(({ __typename, ...oh }: any) => oh)
            ?.sort((a: any, b: any) => sortByDayOfWeek(a.day, b.day)),
          timezone: data?.fetchOrganization.timezone,
          double_booking: data?.fetchOrganization.double_booking,
          enable_time_constraint: data?.fetchOrganization.enable_time_constraint,
          show_historical_events: data?.fetchOrganization.show_historical_events,
        }}
        validationSchema={stepOneSchema}
        onSubmit={async ({
          demo_duration,
          demo_overflow,
          callback_duration,
          callback_overflow,
          decision_call_duration,
          decision_call_overflow,
          organization_hours,
          timezone,
          double_booking,
          enable_time_constraint,
        }) => {
          await configureGlobalSchedule({
            variables: {
              data: {
                demo_overflow: typeof demo_overflow === "string" ? parseInt(demo_overflow) : demo_overflow,
                demo_duration: typeof demo_duration === "string" ? parseInt(demo_duration) : demo_duration,
                callback_duration:
                  typeof callback_duration === "string" ? parseInt(callback_duration) : callback_duration,
                callback_overflow:
                  typeof callback_overflow === "string" ? parseInt(callback_overflow) : callback_overflow,
                decision_call_duration:
                  typeof decision_call_duration === "string"
                    ? parseInt(decision_call_duration)
                    : decision_call_duration,
                decision_call_overflow:
                  typeof decision_call_overflow === "string"
                    ? parseInt(decision_call_overflow)
                    : decision_call_overflow,
                organization_hours,
                timezone,
                double_booking,
                enable_time_constraint,
              },
            },
          });
        }}
      >
        {({ dirty, submitForm, values, setFieldValue, isSubmitting, errors }: FormikProps<MyFormikProps>) => {
          return (
            <>
              <Page>
                <PhoenixStyledTooltip id="scheduling-tooltip" fontSize={10} lineHeight={"1rem"} place="bottom" />
                <TopAlignDiv>
                  <OptionsDiv>
                    <OptionsLeft>
                      <RowDiv>
                        <AppText fontSize={22} fontWeight={500} noWrap>
                          Scheduling Details
                        </AppText>
                        <PhoenixIcon
                          data-tip={tooltipText || "Scheduling Details"}
                          data-for="scheduling-tooltip"
                          variant="brand"
                          svg={info}
                          size={16}
                        />
                      </RowDiv>
                    </OptionsLeft>
                    <OptionsRight />
                  </OptionsDiv>
                </TopAlignDiv>

                <Main>
                  <>
                    <Container>
                      <MainGrid>
                        <ContentSegment>
                          <AppText fontSize={20} fontWeight={500}>
                            Company Hours
                          </AppText>
                          <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
                            Configure the opening and closing times for your Organization.
                          </AppText>
                          <SpacerDiv height={16} />
                          <div
                            style={{
                              width: 200,
                            }}
                          >
                            <NewSelectField
                              titleInInput
                              title="TIME ZONE"
                              name="timezone"
                              placeholder="Select Time Zone"
                              options={timezone_list_items}
                              noErrorNeeded
                              onChangeSideEffect={() => {
                                triggerDebounce();
                              }}
                            />
                          </div>
                          <SpacerDiv height={4} />
                          <TimeGrid>
                            {/* this is a grid of 3 columns */}
                            <CompanyHoursTableTitle></CompanyHoursTableTitle>
                            <CompanyHoursTableTitle>Start time</CompanyHoursTableTitle>
                            <CompanyHoursTableTitle>End time</CompanyHoursTableTitle>

                            {/* now we map over the days of the week */}

                            {values?.organization_hours?.map((hour, index) => (
                              // this is a row of values
                              <React.Fragment key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <AppText style={{ lineHeight: "28px" }} fontSize={14}>
                                    {hour?.day}
                                  </AppText>
                                </div>
                                <TimePickerContainer>
                                  <NewTimeField
                                    name={`organization_hours[${index}]`}
                                    timeType="start"
                                    onChangeSideEffect={() => {
                                      triggerDebounce();
                                    }}
                                  />
                                </TimePickerContainer>
                                <TimePickerContainer>
                                  <NewTimeField
                                    name={`organization_hours[${index}]`}
                                    timeType="end"
                                    onChangeSideEffect={() => {
                                      triggerDebounce();
                                    }}
                                  />
                                </TimePickerContainer>
                              </React.Fragment>
                            ))}
                          </TimeGrid>
                          {/* end of row of values */}
                          {/* end of mapping over days of the week */}
                        </ContentSegment>
                        <ContentSegment>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <AppText fontSize={12} fontWeight={500}>
                              Custom Dates
                            </AppText>
                            <div
                              style={{
                                display: "flex",
                                gap: 8,
                              }}
                            >
                              <PhoenixAppButton
                                variant="brand"
                                buttonType="secondary"
                                onClick={() => {
                                  setShowNoEventModal(true);
                                  setNoEventModalData({
                                    event_id: null,
                                  });
                                }}
                              >
                                <AppText fontSize={10} fontWeight={600} color={theme.WHITE_COLOR}>
                                  ADD DATE
                                </AppText>
                              </PhoenixAppButton>
                              <div
                                style={{
                                  width: 125,
                                }}
                              >
                                <NewAppSelect
                                  noErrorNeeded
                                  options={CUSTOM_YEAR_OPTIONS}
                                  value={selectedCustomEventDateYear}
                                  onChange={(e) => {
                                    if (!e) return;

                                    if (e.value === "all") {
                                      setSelectedCustomEventDateYear("all");
                                      return;
                                    }

                                    setSelectedCustomEventDateYear(parseInt(e.value));
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <SpacerDiv height={8} />
                          <CustomDateTable selectedYear={selectedCustomEventDateYear} />
                          <SpacerDiv height={32} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <AppText fontSize={12} fontWeight={500}>
                              Federal Holidays
                            </AppText>
                            <div
                              style={{
                                width: 125,
                              }}
                            >
                              <NewAppSelect
                                noErrorNeeded
                                options={FEDERAL_YEAR_OPTIONS}
                                value={selectedFederalYear}
                                onChange={(e) => {
                                  !!e && setSelectedFederalYear(parseInt(e.value));
                                }}
                              />
                            </div>
                          </div>
                          <SpacerDiv height={8} />
                          <FederalDateTable selectedYear={selectedFederalYear} />
                        </ContentSegment>
                      </MainGrid>
                    </Container>
                    <Container>
                      <ContainerTitle>Duration and Overflow Times</ContainerTitle>
                      <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
                        Set duration and overflow times for demos, decision calls, and call backs.
                      </AppText>
                      <SpacerDiv height={24} />
                      <SwitchDiv>
                        <Switch
                          onChange={(checked) => {
                            setFieldValue("double_booking", checked);
                            triggerDebounce();
                          }}
                          checked={values.double_booking}
                          onColor={theme.PRIMARY500}
                          offColor={theme.NEUTRAL200}
                          height={16}
                          width={32}
                          handleDiameter={12}
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                        <AppText fontWeight={500} fontSize={12}>
                          Allow reps to book multiple events at one time
                        </AppText>
                      </SwitchDiv>
                      <SpacerDiv height={24} />

                      <DurationGrid>
                        <DurationGridCell>
                          <AppText fontSize={12} fontWeight={500}>
                            Demo Duration
                          </AppText>
                          <NewSelectField
                            name="demo_duration"
                            isRequired
                            placeholder="Select Demo Duration"
                            options={demotimeminsOptions?.map((item: number) => ({
                              label: `${item} minutes`,
                              value: item,
                            }))}
                            onChangeSideEffect={() => {
                              triggerDebounce();
                            }}
                          />
                        </DurationGridCell>
                        <DurationGridCell>
                          <AppText fontSize={12} fontWeight={500}>
                            Demo Time Overflow (in minutes)
                          </AppText>

                          <PhoenixInputField
                            name="demo_overflow"
                            type="number"
                            value={values.demo_overflow}
                            onChange={(e: any) => {
                              setFieldValue("demo_overflow", e.target.value);
                              triggerDebounce();
                            }}
                          />
                        </DurationGridCell>

                        <DurationGridCell>
                          <AppText fontSize={12} fontWeight={500}>
                            Decision Call Duration
                          </AppText>
                          <NewSelectField
                            name="decision_call_duration"
                            options={demotimeminsOptions?.map((item: number) => ({
                              label: `${item} minutes`,
                              value: item,
                            }))}
                            onChangeSideEffect={() => {
                              triggerDebounce();
                            }}
                          />
                        </DurationGridCell>
                        <DurationGridCell>
                          <AppText fontSize={12} fontWeight={500}>
                            Decision Call Time Overflow (in minutes)
                          </AppText>
                          <PhoenixInputField
                            name="decision_call_overflow"
                            type="number"
                            value={values.decision_call_overflow}
                            onChange={(e: any) => {
                              setFieldValue("decision_call_overflow", e.target.value);
                              triggerDebounce();
                            }}
                          />
                        </DurationGridCell>

                        <DurationGridCell>
                          <AppText fontSize={12} fontWeight={500}>
                            Call Back Duration
                          </AppText>
                          <NewSelectField
                            name="callback_duration"
                            options={callbackminsOptions?.map((item: number) => ({
                              label: `${item} minutes`,
                              value: item,
                            }))}
                            onChangeSideEffect={() => {
                              triggerDebounce();
                            }}
                          />
                        </DurationGridCell>
                        <DurationGridCell>
                          <AppText fontSize={12} fontWeight={500}>
                            Call Back Time Overflow (in minutes)
                          </AppText>
                          <PhoenixInputField
                            name="callback_overflow"
                            type="number"
                            value={values.callback_overflow}
                            onChange={(e: any) => {
                              setFieldValue("callback_overflow", e?.target?.value);
                              triggerDebounce();
                            }}
                          />
                        </DurationGridCell>
                      </DurationGrid>
                    </Container>
                    <Container>
                      <ContainerTitle>Manage Timeslot Availability</ContainerTitle>

                      <SpacerDiv height={24} />
                      <SwitchDiv>
                        <Switch
                          onChange={(checked) => {
                            setFieldValue("enable_time_constraint", checked);
                            triggerDebounce();
                          }}
                          checked={values.enable_time_constraint}
                          onColor={theme.PRIMARY500}
                          offColor={theme.NEUTRAL200}
                          height={16}
                          width={32}
                          handleDiameter={12}
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                        <AppText fontWeight={500} fontSize={12}>
                          Enable constraints on timeslots
                        </AppText>
                        <PhoenixIcon
                          svg={info}
                          size={16}
                          data-for="scheduling-tooltip"
                          variant="brand"
                          data-tip="Determine the maximum number of overlapping demos and decision calls that can be scheduled by all SDRs and AEs in your organization. These settings help ensure that you will have enough available AEs to run those demos and decision calls. If you do not want a maximum, either leave this setting toggled off or do not enter a value for the applicable days of the week."
                        />
                      </SwitchDiv>
                      <SpacerDiv height={16} />
                      {values.enable_time_constraint && (
                        <ContentSegment>
                          <TimeslotGrid>
                            {DAYS_OF_THE_WEEK?.map((day: string) => (
                              <React.Fragment key={day}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                  <AppText style={{ lineHeight: "28px" }} fontSize={14}>
                                    {day}
                                  </AppText>
                                </div>
                                <TimePickerContainer>
                                  <PhoenixInput
                                    showNumberArrows
                                    type="number"
                                    handleNumberArrowUpClick={() => {
                                      const newTimeslotData = timeslotData?.map((item) => {
                                        if (item.day === day) {
                                          return {
                                            ...item,
                                            max_booking: item.max_booking ? item.max_booking + 1 : 1,
                                          };
                                        }
                                        return item;
                                      });

                                      setTimeslotData(newTimeslotData);
                                    }}
                                    handleNumberArrowDownClick={() => {
                                      const newTimeslotData = timeslotData?.map((item) => {
                                        if (item.day === day) {
                                          return {
                                            ...item,
                                            max_booking: item.max_booking ? item.max_booking - 1 : 0,
                                          };
                                        }
                                        return item;
                                      });

                                      setTimeslotData(newTimeslotData);
                                    }}
                                    value={timeslotData?.find((item) => item.day === day)?.max_booking}
                                    onChange={(e: any) => {
                                      const newTimeslotData = timeslotData?.map((item) => {
                                        if (item.day === day) {
                                          return {
                                            ...item,
                                            max_booking: e.target.value
                                              ? typeof e.target.value === "string"
                                                ? parseInt(e.target.value)
                                                : e.target.value
                                              : null,
                                          };
                                        }
                                        return item;
                                      });

                                      setTimeslotData(newTimeslotData);
                                    }}
                                  />
                                </TimePickerContainer>
                              </React.Fragment>
                            ))}
                          </TimeslotGrid>
                        </ContentSegment>
                      )}
                    </Container>

                    {showHistoricalEventsToggle && (
                      <Container>
                        <ContainerTitle>Calendar Historical Events</ContainerTitle>
                        <AppText
                          fontSize={12}
                          fontWeight={400}
                          color={theme.text.neutral.secondary}
                          style={{ width: "580px", paddingTop: "16px" }}
                        >
                          If enabled, calendar will retain deleted events in a "disabled" state, still allowing access
                          to event details but preventing rescheduling if an event has been dispositioned.
                        </AppText>

                        <SpacerDiv height={24} />

                        <SwitchDiv>
                          <Switch
                            onChange={async (checked) => {
                              setFieldValue("show_historical_events", checked);
                              await updateOrgShowHistoricalEvents({
                                variables: {
                                  show_historical_events: checked,
                                },
                              });
                            }}
                            checked={values.show_historical_events}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            height={16}
                            width={32}
                            handleDiameter={12}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            disabled={loadingUpdateOrgShowHistoricalEvents}
                          />
                          <AppText fontWeight={500} fontSize={12}>
                            Show Historical Events
                          </AppText>
                        </SwitchDiv>
                      </Container>
                    )}
                  </>
                </Main>
              </Page>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 160px;

  width: 100%;
  height: 100%;
`;

const ContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const DurationGridCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  width: 300px;
`;

const TimeslotGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  width: 300px;
  height: 400px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.WHITE_COLOR};
  padding: 24px;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  margin-bottom: 16px;
  overflow: auto;
`;

const DurationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  width: 550px;
`;

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
`;

const TimePickerContainer = styled.div`
  height: 35px;
  width: 101px;
`;

const CompanyHoursTableTitle = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  width: 100px;
  margin-top: 25px;
  /* text-align: center; */
`;

const ContentSegment = styled.div``;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Page = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${theme.LIGHT_BLUE};
  width: 100%;
  height: fit-content;

  border-top: 1px solid ${theme.NEUTRAL200};
  gap: 16px;
  padding: 48px;
  margin: 0px;
`;

const TopAlignDiv = styled.div`
  height: 90px;
  display: flex;
  justify-items: center;
  margin: 0px 24px;
`;

const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const OptionsLeft = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const OptionsRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export { StepTimesV2 };
